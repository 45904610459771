/* src/app/modules/public/internal-pages/imprint/imprint.component.scss */
@media (max-width: 576px) {
  .footer-route-background .internal-page .page-title {
    margin-bottom: 0;
  }
  .footer-route-background .internal-page .internal-page-column {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
.internal-page-column p {
  margin-bottom: 0 !important;
}
.footer-route-background {
  background:
    linear-gradient(
      0deg,
      rgb(9, 174, 224),
      rgb(7, 123, 208),
      rgb(15, 41, 118));
  position: relative;
  padding: 5rem 0;
}
.internal-page {
  color: #5D5D5D;
  max-width: 75%;
  margin: 4rem auto;
  padding: 4rem;
  background: white;
}
.internal-page .internal-page-section {
  padding: 0;
}
.internal-page .page-title {
  font-size: 24px;
  color: #265ca0;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.internal-page .internal-section-title {
  font-size: 18px !important;
  font-weight: 500;
  line-height: 1.4;
  color: #2f79d5;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 10px;
  display: table;
}
.internal-page .internal-section-subtitle {
  font-size: 16px;
  color: #265ca0;
  text-transform: uppercase;
  display: table;
  margin-top: 15px;
  margin-bottom: 5px;
}
.internal-page .internal-section-title-bold {
  font-weight: 500;
  display: table;
}
.internal-page .list {
  margin-left: 30px;
}
.internal-page .list li {
  line-height: 1.5;
  color: #787878;
  list-style: disc;
}
.internal-page p,
.internal-page a {
  line-height: 1.5;
  color: #787878;
  text-align: left;
  white-space: pre-wrap;
}
.internal-page .false-link {
  color: #2589D8 !important;
}
.internal-page p .bold {
  font-weight: 500;
}
.internal-page p a {
  display: inline;
}
.internal-page a {
  display: table;
  color: #2589D8;
}
.internal-page a:hover {
  color: #105C98;
}
.logo {
  max-width: 100%;
  height: auto;
  width: 200px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
/*# sourceMappingURL=imprint.component-EXQCTRPE.css.map */
